import client from '../lib/http-client';
import { buildQuery } from '../lib/helper';

const endpoint = '/currencies';

export default {
  // Create currencies
  create(params, cb, errorCb) {
    const url = endpoint;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get
  get(id, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get Currencies
  getCurrencies(params, cb, errorCb) {
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    const query = buildQuery(params);
    const url = `${endpoint}?${query}`;
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },


  // Bulk Delete
  bulkDelete(ids, cb, errorCb) {
    const params = {
      ids: JSON.stringify(ids),
      // is_deleted: 1,
    };
    const url = `${endpoint}/bulk/delete`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.delete(url, { data: params })
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Update currencies
  update(id, params, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    client.put(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Delete currencies
  delete(id, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    client.delete(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  bulkCreate(currencies, cb, errorCb) {
    const url = `${endpoint}/bulk/create`;
    const params = { currencies: JSON.stringify(currencies) };
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Export currencies
  exportList(type, params, cb, errorCb) {
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    const query = buildQuery(params);
    const url = `${endpoint}/export/${type}?${query}`;
    client.get(url, { responseType: 'blob' })
      .then(responseHandler)
      .catch(errorHandler);
  },
};
