<template>
	<vs-sidebar
		click-not-close
		position-right
		parent="body"
		default-index="1"
		color="primary"
		class="add-new-data-sidebar items-no-padding"
		spacer
		v-model="isSidebarActiveLocal"
	>
		<div class="mt-6 flex items-center justify-between px-6">
			<h4>{{ Object.entries(this.data).length === 0 ? $t('Add New') : $t('Update') }} {{ $t('Currencies') }}</h4>
			<feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
		</div>
		<vs-divider class="mb-0"></vs-divider>

		<component
			:is="scrollbarTag"
			class="scroll-area--data-list-add-new"
			:settings="settings"
			:key="$vs.rtl"
		>
			<div class="p-6">
				<div class="vx-col w-full mb-2">
					<!-- Name -->
					<vs-input v-filter-input
						:label-placeholder="$t('Name')"
						v-model="dataName"
						class="mt-8 w-full"
						name="item-name"
						v-validate="'required'"
					/>
					<span
						class="text-danger text-sm"
						v-show="errors.has('item-name')"
						>
						{{ errors.first('item-name') }}
					</span>
				</div>
				<div class="vx-col w-full mb-2">
					<!-- Code -->
					<vs-input v-filter-input
						:label-placeholder="$t('Code')"
						v-model="dataCode"
						class="mt-8 w-full"
						name="item-code"
						v-validate="'required'"
					/>
					<span
						class="text-danger text-sm"
						v-show="errors.has('item-code')"
					>{{ errors.first('item-code') }}</span>
				</div>
				<div class="vx-col w-full mb-2">
					<!-- Country -->
					<vs-input v-filter-input
						:label-placeholder="$t('Country')"
						v-model="dataCountry"
						class="mt-8 w-full"
						name="item-country"
						v-validate="'required'"
					/>
					<span
						class="text-danger text-sm"
						v-show="errors.has('item-country')"
					>{{ errors.first('item-country') }}</span>
				</div>
			</div>
		</component>

		<div class="flex flex-wrap items-center p-6" slot="footer">
			<vs-button
				class="mr-6"
				:class="{ 'is-loading': isSaving }"
				:disabled="!isFormValid"
				@click="submitData">
				{{ $t('Save') }}
			</vs-button>
			<vs-button color="danger" @click="isSidebarActiveLocal = false">{{ $t('Cancel') }}</vs-button>
		</div>
	</vs-sidebar>
</template>

<script>
/* eslint-disable camelcase */
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { getAxiosErrorMessage, duplicateVar } from '../lib/helper';
import currenciesApi from '../api/currency';

export default {
  name: 'CurrenciesSidebar',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      dataId: null,
      dataName: '',
      dataCode: '',
      dataCountry: '',
      isSaving: false,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        this.setData();
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar');
          this.$validator.reset();
          this.initValues();
        }
      },
    },
    isFormValid() {
      return (
        !this.errors.any()
				&& this.dataName
				&& this.dataCode
				&& this.dataCountry
      );
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    params() {
      const params = {
        id: this.dataId,
        name: this.dataName,
        code: this.dataCode,
        country: this.dataCountry,
      };
      return params;
    },
  },
  methods: {
    setData() {
      const {
        id,
        name,
        code,
        country,
      } = duplicateVar(this.data);
      this.dataId = id;
      this.dataName = name;
      this.dataCode = code;
      this.dataCountry = country;
      this.initValues();
    },
    initValues() {
      if (this.data.id) return;
      this.dataId = null;
      this.dataName = '';
      this.dataCode = '';
      this.dataCountry = '';
    },
    submitData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.isSaving = true;
          const isUpdate = !!this.dataId;
          const params = this.params;
          const callback = (response) => {
            const currency = response.data;
            const message = response.message;
            this.$emit('add', currency);
            this.$emit('closeSidebar');
            this.initValues();
            this.$vs.notify({
              title: this.$t('Currency'),
              text: message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success',
            });
            this.isSaving = false;
          };
          const errorCallback = (e) => {
            const message = getAxiosErrorMessage(e);
            this.$vs.notify({
              title: this.$t('Currency'),
              text: message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            });
            this.isSaving = false;
          };
          if (isUpdate) {
            currenciesApi.update(this.dataId, params, callback, errorCallback);
          } else {
            currenciesApi.create(params, callback, errorCallback);
          }
        }
      });
    },
  },
};
</script>
